// src/components/Loader.js
import React from 'react';
import './Loader.css'; 

const Loader = () => {
    return (
        <div className="loader-overlay">
            <div ><img className="loader" src='https://static.vecteezy.com/system/resources/previews/028/766/696/non_2x/astrology-wheel-with-zodiac-signs-icon-png.png' alt='load'></img></div>
        </div>
    );
};

export default Loader;
