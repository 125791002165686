import React from 'react'
import Nav from './Nav'
const ReturnAndRefund = () => {
  return (
    <div>
      <div data-custom-class="body">
        <div>
          <div
            align="center"
            className="MsoNormal"
            style={{ textAlign: "center", lineHeight: "115%" }}
          >
            <a name="_2cipo4yr3w5d"></a>
            <div
              align="center"
              className="MsoNormal"
              style={{ textAlign: "left", lineHeight: "150%" }}
            >
              <strong>
                <span style={{ fontSize: "26px" }}>
                  <span data-custom-class="title">RETURN POLICY</span>
                </span>
              </strong>
            </div>
            <div
              align="center"
              className="MsoNormal"
              style={{ textAlign: "left", lineHeight: "150%" }}
            >
              <br />
            </div>
            <div
              align="center"
              className="MsoNormal"
              style={{ textAlign: "left", lineHeight: "150%" }}
            >
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="subtitle">
                      Last updated <bdt className="question">16/09/2024</bdt>
                    </span>
                  </strong>
                </span>
              </span>
            </div>
            <div
              align="center"
              className="MsoNormal"
              style={{ textAlign: "left", lineHeight: "150%" }}
            >
              <br />
            </div>
            <div
              align="center"
              className="MsoNormal"
              style={{ textAlign: "left", lineHeight: "150%" }}
            >
              <span style={{ fontSize: "15px" }}>
                <br />
                <a name="_2cipo4yr3w5d"></a>
              </span>
            </div>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)",
              }}
            >
              <bdt className="block-component"></bdt>
            </span>
          </div>
          <div data-custom-class="heading_1">
            <strong>
              <span style={{ fontSize: "19px" }}>REFUNDS</span>
            </strong>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              All sales are final and no refund will be issued.
              <bdt className="block-component"></bdt>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            data-custom-class="heading_1"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{ fontSize: "19px", color: "rgb(0, 0, 0)" }}
            >
              <strong>QUESTIONS</strong>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
              If you have any questions concerning our return policy, please
              contact us at:
            </span>
          </div>
          <div style={{ lineHeight: "1.1" }}>
            <br />
          </div>
          <div
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "15px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <bdt className="question">hello@escenem.in</bdt>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReturnAndRefund
