import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../firebase'; // Ensure correct paths
import { signOut } from 'firebase/auth'; // Import signOut function
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import './Profile.css';
import Nav from './Nav.js';
import Footer from './Footer.js';
import appConfig from '../config/appConfig.json';

const Profile = () => {
  const [user] = useAuthState(auth);
  const [profileData, setProfileData] = useState(user);
  const [isEditing, setIsEditing] = useState(false); // Control when the user is in "edit" mode

  const [editName, setEditName] = useState('');
  const [editDOB, setEditDOB] = useState('');
  const [editTime, setEditTime] = useState('');
  const [editLocation, setEditLocation] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [debouncedLocation] = useDebounce(editLocation, 500); // Adding debounce
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [locationSelected, setLocationSelected] = useState(false); // Flag to stop further API calls once a location is selected

  const navigate = useNavigate(); // For redirection

  useEffect(() => {
    // If the user is not logged in, redirect to authentication page
    if (!user) {
      navigate('/authentication');
      return;
    }

    const fetchUserData = async () => {
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setProfileData(data);

          // Set initial values for edit fields
          setEditName(data.name || '');
          setEditDOB(data.dob || '');
          setEditTime(data.time || '');
          setEditLocation(data.location || '');
          setLat(data.lat || null);
          setLon(data.lon || null);
        }
      }
    };

    fetchUserData();
  }, [user, navigate]);

  useEffect(() => {
    // Fetch location predictions only when editing and debounce location input
    if (debouncedLocation && isEditing && !locationSelected) {
      fetchPredictions(debouncedLocation);
    }
  }, [debouncedLocation, isEditing, locationSelected]);

  // Fetch location predictions
  const fetchPredictions = async (input) => {
    try {
      const response = await axios.get(`${appConfig.REACT_APP_BACKEND_URL}/geocode/autocomplete`, {
        params: { text: input } // Pass the input as a query parameter
      });

      // Filter only the name (city) field and set predictions
      if (response.data) {
        const cityPredictions = response.data.map((prediction) => ({
          name: prediction.name,
          lat: prediction.lat,
          lon: prediction.lon,
        }));
        setPredictions(cityPredictions);
      }
    } catch (error) {
      console.error('Error fetching location predictions:', error);
    }
  };

  const handleLocationChange = (e) => {
    setLocationSelected(false); // Reset flag to allow API calls if user is typing again
    setEditLocation(e.target.value);
  };

  const handlePredictionClick = (prediction) => {
    setEditLocation(prediction.name);
    setLat(prediction.lat); // Save latitude
    setLon(prediction.lon); // Save longitude
    setPredictions([]); // Clear predictions after selection
    setLocationSelected(true); // Set flag to stop further API calls
  };

  // Handle the update of the user profile data
  const handleUpdateProfile = async () => {
    try {
      const updatedData = {
        name: editName,
        dob: editDOB,
        time: editTime,
        location: editLocation,
        lat,
        lon,
      };

      // Update Firestore with the new data
      const userRef = doc(firestore, 'users', user.uid);
      await setDoc(userRef, updatedData, { merge: true });

      // Update local state
      setProfileData(updatedData);
      setIsEditing(false); // Exit editing mode
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  // Handle user logout
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      navigate('/'); // Redirect to authentication page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <>
      <Nav />
      <div className="profile-container">
        {/* Profile Header */}
        <div className="profile-header">
          <img src={profileData.photoURL || 'https://via.placeholder.com/120'} alt="Profile" />
          <h3>{profileData.email}</h3>
        </div>

        {/* Profile Details */}
        <div className="profile-details">
          <div>
            <label>Name:</label>
            {isEditing ? (
              <input
                type="text"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            ) : (
              <div className="value">{profileData.displayName || profileData.name ||"Name not provided"}</div>
            )}
          </div>

          <div>
            <label>Date of Birth:</label>
            {isEditing ? (
              <input
                type="date"
                value={editDOB}
                onChange={(e) => setEditDOB(e.target.value)}
              />
            ) : (
              <div className="value">{profileData.dob || "DOB not provided"}</div>
            )}
          </div>

          <div>
            <label>Time of Birth:</label>
            {isEditing ? (
              <input
                type="time"
                value={editTime}
                onChange={(e) => setEditTime(e.target.value)}
              />
            ) : (
              <div className="value">{profileData.time || "Birth Time not provided"}</div>
            )}
          </div>

          <div>
            <label>Place of Birth:</label>
            {isEditing ? (
              <input
                type="text"
                value={editLocation}
                onChange={handleLocationChange}
              />
            ) : (
              <div className="value">{profileData.location || "Birth Location not provided"}</div>
            )}
            {/* Display location predictions if editing */}
            {isEditing && predictions.length > 0 && (
              <ul className="predictions">
                {predictions.map((prediction, index) => (
                  <li key={index} onClick={() => handlePredictionClick(prediction)}>
                    {prediction.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Edit and Update Buttons */}
        <div className="profile-actions">
          {isEditing ? (
            <button onClick={handleUpdateProfile}>Update</button>
          ) : (
            <button onClick={() => setIsEditing(true)}>Edit</button>
          )}
          <button onClick={handleLogout}>Logout</button> {/* Logout Button */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
