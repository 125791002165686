import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../components/Nav.css';
import { useNavigate } from 'react-router-dom';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, googleProvider, firestore } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';


const Nav = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleGoogleLogin = async () => {
    // setButtonLoading(true); 
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const userRef = doc(firestore, 'users', user.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        await setDoc(userRef, {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        });
      }

      navigate('/form');
    } catch (error) {
      console.error('Error signing in with Google:', error);
      alert('Error signing in with Google');
    } finally {
      
    }
  };

  return (
    <div
      className='nav-bar'
      style={{
        backgroundColor: scrollY > 45 ? 'white' : 'black',
        transition: 'background-color 0.3s ease',
      }}
    >
      <Link 
        to="/" 
        className='gradient-text'
      >
        VedicRashi
      </Link>
      
      <div className={`hamburger ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu} style={{
        transition: 'background-color 0.3s ease',
      }}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      
      <div 
        id='nav-right' 
        className={`${isMobileMenuOpen ? 'open' : ''}`}
        style={{
          backgroundColor: scrollY > 45 ? 'white' : 'black',
          transition: 'background-color 0.3s ease',
        }}
      >
        <Link className='link' style={{
            color:  scrollY > 45 ? 'black' : 'white',
          }} to="/about">About</Link>
        <Link 
          to="/pricing" 
          className='link'
          style={{
            color:  scrollY > 45 ? 'black' : 'white',
          }}
        >
          Pricing
        </Link>
        <Link className='link' style={{
            color:  scrollY > 45 ? 'black' : 'white',
          }} to="/feedback">Feedback
        </Link>
        {isLoggedIn ? (
          <Link 
            to="/profile" 
            className='link'
            style={{
              color: scrollY > 45 ? 'black' : 'white',
            }}
          >
            Profile
          </Link> 
        ) : (
          <Link 
            to="/" 
            className='link'
            style={{
              color: scrollY > 45 ? 'black' : 'white',
            }}
            onClick={handleGoogleLogin}
          >
            Login
          </Link>
        )}
      </div>
    </div>
  );
};

export default Nav;
