import React from 'react'
import './ZoadicSign.css'
import { useNavigate } from 'react-router-dom';

const ZoadicSign = () => {
    const navigate = useNavigate();

    const handleNavigate = () =>{
        navigate('/chat')
    }
  return (
    <div className='zoadic-container'>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/05/ARIES.png' alt='sign' />
            <p>( Mar 21 - Apr 19 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/TAURUS-1.png' alt='sign' />
            <p>( Apr 20 - May 20 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/GEMINI.png' alt='sign' />
            <p>( May 21 - Jun 20 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/CANCER-1.png' alt='sign' />
            <p>( Jun 21 - Jul 22 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/LEO-1.png' alt='sign' />
            <p>( Jul 23 - Aug 22 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/VIRGO-1.png' alt='sign' />
            <p>( Aug 23 - Sep 22 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/LIBRA-1.png' alt='sign' />
            <p>( Sep 22 - Oct 23 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/SCORPIO-1.png' alt='sign' />
            <p>( Oct 23 - Nov 21 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/SAGITTARIUS.png' alt='sign' />
            <p>( Nov 22 - Dec 21 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/CAPRI-1.png' alt='sign' />
            <p>( Dec 22 - Jan 19 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/AQUARI-1.png' alt='sign' />
            <p>( Mar21 - Apr19 )</p>
        </div>
        <div className='zoadic-cards' onClick={handleNavigate}>
            <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/PISCES-1.png' alt='sign' />
            <p>( Mar21 - Apr19 )</p>
        </div>
    </div>
  )
}

export default ZoadicSign
