import React from 'react'
import './About.css'
import Nav from './Nav'
import Footer from './Footer'
const About = () => {
  return (
    <div>
        <Nav />
        <div className='about'>
            <h1> About VedicRashi</h1>
            <h3>Simplifying Vedic Astrology with AI.</h3>
            <p>Welcome to VedicRashi, where ancient wisdom meets modern technology! We're a passionate team committed to simplifying the profound insights of Vedic Astrology with cutting-edge AI. At AstroAnswer, we’ve designed an intelligent chatbot that uses your kundli to help you unlock the secrets of your future and navigate life’s journey with confidence.
            Our AI-powered Vedic Astrologer is here to guide you through every aspect of life. Whether you’re curious about your career, seeking harmony in your relationships, or eager to boost your finances, VedicRashiprovides personalized insights just for you. Wondering about upcoming milestones or challenges? Our chatbot analyzes the planetary positions in your kundli to predict events that can shape your path ahead.    Concerned about doshas or negative influences? We’ve got you covered! Our chatbot not only identifies potential obstacles but also suggests effective remedies to transform challenges into opportunities. Plus, if health matters are on your mind, our predictions will help you stay prepared with preventive measures rooted in astrological wisdom.VedicRashiisn’t just a service—it’s your personal guide to understanding yourself and your future better. Start your journey with our AI-driven astrologer and explore life’s possibilities today!
            </p><br />
            <h1>Disclaimer</h1>
            <p>VedicRashiis an experimental tool designed for informational and entertainment purposes only. It is not intended for use in any commercial capacity. While we strive to provide accurate and reliable astrological insights, we make no representations or warranties, express or implied, regarding the completeness, accuracy, reliability, suitability, or availability of the information, services, or related graphics found on this website. Any reliance you place on the information provided by VedicRashiis strictly at your own risk. We do not assume any liability for loss or damage, including but not limited to indirect or consequential loss, or any loss of data or profits arising from the use of this website.</p>
        </div>
        <Footer />
    </div>
  )
}

export default About
