import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from './firebase';
import { setUserData } from './redux/userSlice';
import { doc, getDoc } from 'firebase/firestore';
import UserForm from './components/UserForm';
import ChatInterface from './components/ChatInterface';
import LandingPage from './components/LandingPage';
import Profile from './components/Profile';
import PricingPage from './components/PricingPage';
import Authentication from './components/Authentication';
import './App.css';
import Loader from './components/Loader'; 
import About from './components/About';
import ReturnAndRefund from './components/ReturnAndRefund';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import FeedbackPage from './components/FeedbackPage';

const App = () => {
  const [user, loadingAuth] = useAuthState(auth);
  const [loadingData, setLoadingData] = useState(true);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  

  useEffect(() => {
    const checkUserProfile = async (uid) => {
      try {
        const userRef = doc(firestore, 'users', uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          dispatch(setUserData(docSnap.data()));
        } else {
          dispatch(setUserData(null));
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
        dispatch(setUserData(null));
      } finally {
        setLoadingData(false);
      }
    };

    if (user) {
      checkUserProfile(user.uid);
    } else {
      setLoadingData(false); // No user, so we're done loading data
    }
  }, [user, dispatch]);

  if (loadingAuth || loadingData) {
    return <Loader />; 
  }

  // Private route no longer requires authentication check for /chat
  // const PrivateRoute = ({ children }) => {
  //   if (!userData || !userData.dob || !userData.time || !userData.location) {
  //     return <Navigate to="/form" />;
  //   }
  //   return children;
  // };

  // FormRoute only checks userData fields
  const FormRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/" />;
    }
    if (userData && userData.dob && userData.time && userData.location) {
      return <Navigate to="/chat" />;
    }
    return children;
  };
  const AuthRoute = ({ children }) => {
    if (user) {
      // Check if user data contains necessary fields; if not, redirect to the appropriate page
      if (userData && userData.dob && userData.time && userData.location) {
        return <Navigate to="/chat" replace />;
      } else {
        return <Navigate to="/form" replace />;
      }
    }
  
    // If no user is logged in, render the children (e.g., Authentication component)
    return children;
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/authentication" element={<AuthRoute><Authentication /></AuthRoute>} />
        <Route path="/form" element={<FormRoute><UserForm /></FormRoute>} />
        <Route path="/chat" element={<ChatInterface />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/about" element={<About />} />
        <Route path="/feedback" element={<FeedbackPage />} />
        <Route path="/returns" element={<ReturnAndRefund />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/tnc" element={<TermsAndConditions />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
