import React, { useState, useEffect } from 'react';
import axios from 'axios';
import appConfig from '../config/appConfig.json';
import './Feedback.css';

const Feedback = ({ userId, show, onClose, userName, email }) => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0); // For hover effect
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false); // Track successful submission
  const [error, setError] = useState('');  // To track validation errors

  const handleSubmit = async () => {
    if (!rating || rating < 1 || rating > 5) {
      setError('Please provide a rating between 1 and 5.');
      return;
    }

    if (!feedback.trim()) {
      setError('Feedback cannot be empty.');
      return;
    }

    try {
      setError('');
      // Send feedback to backend
      await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/feedback/submit`, {
        userId,
        userName,
        email,
        rating,
        feedback,
      });

      // Show success message instead of alert
      setSuccess(true);
      setSubmitted(true);
      
      // Automatically redirect after 2 seconds back to feedback form
      setTimeout(() => {
        setSuccess(false);
        setSubmitted(false);
        setRating(0);
        setFeedback('');
      }, 2000); // 2 seconds delay before redirecting
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setError('Failed to submit feedback. Please try again.');
    }
  };

  // Function to handle rating change
  const handleRating = (ratingValue) => {
    setRating(ratingValue);
  };

  if (!show) return null;

  return (
    <div className="feedback-popup">
      <div className="feedback-content">
        <p className="close-btn" onClick={onClose}>X</p>  {/* Close button */}

        {/* Show success message if feedback is submitted */}
        {success ? (
          <div className="success-message">
            <img 
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAPGjjvWNqTJAQ4FdU7sUOsxh_izVIH5cvyg&s" 
              alt="Success"
              className="success-image"
            />
            <h2>Feedback Submitted Successfully!</h2>
          </div>
        ) : (
          <>
            <h2>Give Us Your Feedback</h2>
            {error && <p className="error-message">{error}</p>}  {/* Show validation error */}

            {/* Star Rating System */}
            
            <div className='rating-cont'>
              <label>Rate Us:</label>
              <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <span
                      key={index}
                      className={`star ${ratingValue <= (hoverRating || rating) ? "gold" : ""}`}
                      onClick={() => handleRating(ratingValue)}
                      onMouseEnter={() => setHoverRating(ratingValue)}
                      onMouseLeave={() => setHoverRating(0)}
                    >
                      ★
                    </span>
                  );
                })}
              </div>
            </div>

            <div>
              <label>Feedback:</label>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Write your feedback here"
              />
            </div>

            <button onClick={handleSubmit}>Submit</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Feedback;
