import React from "react";
import FAQAccordion from "./FAQAccordion";
import '../components/FAQSection.css'
const FAQSection = () => {
  const faqs = [
    {
      question: "What is your return policy?",
      answer: "Our return policy lasts 30 days...",
    },
    {
      question: "How do I track my order?",
      answer: "You can track your order by...",
    },
    {
      question: "Do you offer gift cards?",
      answer: "Yes, we offer gift cards in various denominations...",
    },
  ];

  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      <p>Let us help answer the most common questions you might have.</p>
      <FAQAccordion faqs={faqs} />
    </div>
  );
};

export default FAQSection;
