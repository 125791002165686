import React from 'react'
import Subscription from './Subscription'
import Nav from './Nav'
import Footer from './Footer.js'
import '../components/PricingPage.css'
import FAQSection from './FAQSection.js'


const PricingPage = () => {
  return (
    <div>
        <Nav />
        <div className='header-section'>
            <img src='Astro-pattern.png' alt='.pattern' id='Astrology-circle'></img>
            <img src='Horoscope-pattern.jpg' alt='bgPattern' id='horoscope-pattern'></img>
            <h1>Astrology Pricing</h1>
            <p>Astro Answer offers flexible pricing options to fit your Kundali Reading. Choose from our weekly, monthly or annual subscription plans.</p>
        </div>
        <img src='https://i.pinimg.com/564x/39/37/71/39377192adcd8c4f4bb72fcc38849acc.jpg' alt='png' className='rangoli' />
        <Subscription />
        <FAQSection />
        <Footer />
    </div>
  )
}

export default PricingPage
