import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import appConfig from '../config/appConfig.json';
import './Feedback.css';
import Nav from './Nav';
import { Link } from 'react-router-dom';

const FeedbackPage = () => {
  const [user] = useAuthState(auth);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0); // For hover effect
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');  // To track validation errors
  const [success, setSuccess] = useState(false); // Track successful submission

  // Function to handle submitting feedback
  const handleSubmit = async () => {
    if (!rating || rating < 1 || rating > 5) {
      setError('Please provide a rating between 1 and 5.');
      return;
    }

    if (!feedback.trim()) {
      setError('Feedback cannot be empty.');
      return;
    }

    try {
      setError('');
      // Send feedback to backend
      await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/feedback/submit`, {
        userId: user.uid,
        userName: user.displayName,
        email: user.email,
        rating,
        feedback,
      });

      // Show success message
      setSuccess(true);
      setSubmitted(true);

      // Reset the form fields and the popup after 2 seconds
      setTimeout(() => {
        setSuccess(false);
        setSubmitted(false);
        setRating(0);
        setFeedback('');
      }, 2000); // 2 seconds delay before resetting
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setError('Failed to submit feedback. Please try again.');
    }
  };

  // Function to handle rating change
  const handleRating = (ratingValue) => {
    setRating(ratingValue);
  };
  if(!user){
    return(
      <>
        <Nav />
        <div className="feedback-popup">
          <div className="feedback-content">
            {/* Show signup message instead of form if user is not singup */}
            <p>You have to SignUp for Feedback</p>
            <Link to="/authentication">SignUp</Link>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Nav />
      <div className="feedback-popup">
        <div className="feedback-content">
          {/* Show success message instead of form if submitted */}
          {success ? (
            <div className="success-message">
              <img 
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAPGjjvWNqTJAQ4FdU7sUOsxh_izVIH5cvyg&s" 
                alt="Success"
                className="success-image"
              />
              <h2>Feedback Submitted Successfully!</h2>
            </div>
          ) : (
            <>
              <h2>Give Us Your Feedback</h2>
              {error && <p className="error-message">{error}</p>}  {/* Show validation error */}
              
              {/* Star Rating System */}
              <div className='rating-cont'>
              <label>Rate Us:</label>
              <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <span
                      key={index}
                      className={`star ${ratingValue <= (hoverRating || rating) ? "gold" : ""}`}
                      onClick={() => handleRating(ratingValue)}
                      onMouseEnter={() => setHoverRating(ratingValue)}
                      onMouseLeave={() => setHoverRating(0)}
                    >
                      ★
                    </span>
                  );
                })}
              </div>
              </div>

              <div>
                <label>Feedback:</label>
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder="Write your feedback here"
                />
              </div>
              <button onClick={handleSubmit}>Submit</button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FeedbackPage;
